
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.panel-default > .panel-heading{
  margin: 0; // reset heading margin
  padding: 20px 24px;
  font-size: 18px;
  font-weight:600;
  background-color: #2F3133;
  color: #fff;
  border-bottom: 1px solid darken($popover-title-bg, 5%);
  border-radius: ($border-radius-large - 1) ($border-radius-large - 1) 0 0;
}
.jumbotron {
    background-color: #FF504A;
    color: #fff;
    padding-left: 150px;
    padding-top: 0px;
    border-bottom: solid 1px;
    border-bottom-color: #c7254e;
    padding-bottom: 0px;
}
.jumbotron h1{
  font-size: 35px;
  color: #fff;
  font-weight: 400;
  padding-top: 0px;
}
hr {
  margin-top:    3px;
  margin-bottom: 3px;
  border: 0;
  border-top: 1px dotted #2F3133;
}
html, body {
  background-color: #FF504A;
  color: #fff;
  font-family: 'Raleway', sans-serif;
  font-weight: 100;
  height: 100vh;
  margin: 0;
}

.navbar-default {
  background-color: #FF504A;
  color: #fff;
  border-color: #c7254e;
}
.navbar-default .navbar-brand {
  color: #fff;
}

.navbar-default .navbar-brand:focus, .navbar-default .navbar-brand:hover {
  color: #133d55;
  background-color: transparent
}
.navbar-default .navbar-nav > li > a, .navbar-default .navbar-text {
  color: #FFFFFF;
}

.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
  color: #133d55;
  background-color: transparent
}
.form-horizontal .control-label{ color: #2F3133;}
.header{ color: #A12821; font-size: 2.2rem;
  font-weight: 600;}
input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control{
  line-height: 1.8rem;
}
.form-horizontal .checkbox { color: #2F3133;}
a.clients{
  color: #FFFFFF;
  font-weight: 600;
  margin-right: 5px;
}

/* visited link */
.clients a:visited {
  color: #FFFFFF;
}

/* mouse over link */
.clients a:hover {
  color: #FFFFFF;
}

/* selected link */
.clients a:active {
  color: #FFFFFF;
}
/*changes to mdb*/
.card-notes{
  text-align: left;
  display: block;
  list-style: none;
  font-size: 22px;
  color: #A12821;
  font-weight: 600;
}
.back-card-notes{
  text-align: left;
  display: block;
  list-style: none;
  font-size: 16px;
  line-height: 16px;
  color: #A12821;
  font-weight: 600;
}
.notes-span{
  color: #000000;
  margin-left: 10px;
}
.card-back-form {
  width: 90%;
}
.form-group-card-back{
  margin: 0px;
  color: #000000;
}
.list-group-item-card-back{
  margin-bottom: 0;
}
.card-back-input input[type=text]{
  background-color:  RGBA(219, 83, 88, .2);
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  outline: none;
  height: 20px;
  width: 100%;
  font-size: 14px;
  line-height: 14px;
  box-shadow: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  transition: all .3s;
  margin: 0;
  padding: 0;
}
.card-back-label{
  display: inline-block;
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  color:#000000;
  float: left;
  margin: 0;
}
.card-notes > a {
  text-align: left;
  display: block;
  list-style: none;
  font-size: 22px;
  color: #A12821;
  font-weight: 600;
  letter-spacing: .1rem;
  text-decoration: none;
}

.card-btn{
  background-color:#A12821; border-radius: 6px;color: #FFFFFF;font-weight: 600;
}
.card-btn-complete{
  background-color:#46BF37; border-radius: 6px;color: #FFFFFF;font-weight: 600; float: right;margin-right: 10px;
}
.card-btn-incomplete{
  background-color:#A12821; border-radius: 6px;color: #FFFFFF;font-weight: 600; float: right; margin-right: 10px;
}
.card-btn >a {
  text-decoration: none;
  color: #FFFFFF;
  font-weight: 600;
  letter-spacing: .1rem;
  font-size: 16px;
}
.card-btn-right{
  background-color:#A12821; border-radius: 6px;color: #FFFFFF;font-weight: 600;float: right;
}
.card-btn-right >a {
  text-decoration: none;
  color: #FFFFFF;
  font-weight: 600;
  letter-spacing: .1rem;
  font-size: 18px;
}
.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin: 5px;
  color: #FFFFFF;
}
.nav > li > a:hover {
  color: #000000;
}
a:not([href]):not([tabindex]) {
  color: #3097D1;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover {
  color: #FF504A;
  text-decoration: none;
}

.card-wrapper .card-up {
  overflow: hidden;
  height: 40%;
  background-color: #999999;
}


.card-wrapper .card-up-two {
  overflow: hidden;
  height: 40%;
  background-color: #3097D1;
}
.card-wrapper .card-up-new-lead {
  overflow: hidden;
  height: 40%;
  background-color: #A12821;
}

h3, .h3 {
  margin-top: 12px;
  margin-bottom: 11px;
  text-align: center;
  padding-right: 10px;
  padding-left: 10px;
}
.card-wrapper {
  height: 500px;
}
.card-wrapper .fa-repeat,
.card-wrapper .fa-undo{
  margin-top: 10px;
}
.card-wrapper .face h4 {
  font-size: 16px;
  font-weight: 600;
  color: #c7254e;
  margin-bottom: 5px;
  padding-top: 0;
  padding-bottom: 0;
}
.card-block p {
  font-size: 13px;
  line-height: 18px;
  margin: 0;
  color: #000000;
}
label {
  font-size: 1.4rem;
  color: darken #ccc, 90%;
}

input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=time],
input[type=date],
input[type=datetime-local],
input[type=tel],
input[type=number],
input[type=search-md],
input[type=search],
textarea.md-textarea {
  font-size: 1.4rem;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{
  background-color: #FFFFFF;
}